/// List of media items, taken from NICE.Bootstrap
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#media-list
.media-list {
  list-style: none;
  margin-left: 0;
  max-width: 100%; // To override the 66ch max-width on lists

  .panel-resources & {
    margin: 0;
  }
}

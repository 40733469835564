@use '@nice-digital/nds-core/scss/colours';

// Copied from /Modules/oforms/Styles/cmxform.css

form.cmxform label.error {
  color: colours.$error;
  font-style: italic;
}

input.plsnobots {
  display: none !important;
}

// Ensure minimum height for textareas that don't have a rows attribute
textarea {
  min-height: 10rem;
}

@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';

// NICE.Bootstrap pager for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html#pagination
.pagination {
  @include layout.clearfix;
  list-style: none;
  margin: utils.rem(spacing.$medium 0 spacing.$large);
  text-align: center;

  li {
    display: inline;

    a {
      background: noc-colours.$bootstrap-pagination-link-background;
      color: noc-colours.$bootstrap-pagination-link-text;
      display: inline-block;
      padding: utils.rem(spacing.$small spacing.$medium);
      text-decoration: none;

      &:hover {
        background: noc-colours.$bootstrap-pagination-link-hover-background;
      }
    }
  }

  .previous {
    a,
    span {
      float: left;
    }
  }

  .next {
    a,
    span {
      float: right;
    }
  }

  .disabled {
    a {
      background: noc-colours.$bootstrap-pagination-link-disabled-background;
      color: noc-colours.$bootstrap-pagination-link-disabled-text;
      cursor: text;
      pointer-events: none;
    }
  }
}

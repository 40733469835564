@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/media-queries';

// stylelint-disable number-max-precision

// Copy of the old Bootstrap grid from v2
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#gridSystem
.row,
.row-fluid {
  @include layout.clearfix;
  margin: 0;
  max-width: 100%; // To override the 66ch max-width on lists
  width: 100%;

  [class*='span'] {
    box-sizing: border-box;
    display: block;
    float: left;
    margin-left: 0;
    width: 100%;
  }

  @include media-queries.mq($from: sm) {
    [class*='span'] {
      float: left;
      margin-left: 2.12765957%;
      min-height: 40px;

      &:first-child {
        margin-left: 0;
      }
    }

    // Magic percentages taken from old Bootstrap
    .span1 {
      width: 6.38297872%;
    }

    .span2 {
      width: 14.89361702%;
    }

    .span3 {
      width: 23.40425532%;
    }

    .span4 {
      width: 31.91489362%;
    }

    .span5 {
      width: 40.42553191%;
    }

    .span6 {
      width: 48.93617021%;
    }

    .span7 {
      width: 57.44680851%;
    }

    .span8 {
      width: 65.95744681%;
    }

    .span9 {
      width: 74.46808511%;
    }

    .span10 {
      width: 82.9787234%;
    }

    .span11 {
      width: 91.4893617%;
    }

    .span12 {
      width: 100%;
    }

    // Offsets
    .offset12 {
      margin-left: 104.25531915%;
    }

    .offset12:first-child {
      margin-left: 102.12765957%;
    }

    .offset11 {
      margin-left: 95.74468085%;
    }

    .offset11:first-child {
      margin-left: 93.61702128%;
    }

    .offset10 {
      margin-left: 87.23404255%;
    }

    .offset10:first-child {
      margin-left: 85.10638298%;
    }

    .offset9 {
      margin-left: 78.72340426%;
    }

    .offset9:first-child {
      margin-left: 76.59574468%;
    }

    .offset8 {
      margin-left: 70.21276596%;
    }

    .offset8:first-child {
      margin-left: 68.08510638%;
    }

    .offset7 {
      margin-left: 61.70212766%;
    }

    .offset7:first-child {
      margin-left: 59.57446809%;
    }

    .offset6 {
      margin-left: 53.19148936%;
    }

    .offset6:first-child {
      margin-left: 51.06382979%;
    }

    .offset5 {
      margin-left: 44.68085106%;
    }

    .offset5:first-child {
      margin-left: 42.55319149%;
    }

    .offset4 {
      margin-left: 36.17021277%;
    }

    .offset4:first-child {
      margin-left: 34.04255319%;
    }

    .offset3 {
      margin-left: 27.65957447%;
    }

    .offset3:first-child {
      margin-left: 25.53191489%;
    }

    .offset2 {
      margin-left: 19.14893617%;
    }

    .offset2:first-child {
      margin-left: 17.0212766%;
    }

    .offset1 {
      margin-left: 10.63829787%;
    }

    .offset1:first-child {
      margin-left: 8.5106383%;
    }
  }

  @include media-queries.mq($from: md) {
    [class*='span'] {
      margin-left: 2.76243094%;

      &:first-child {
        margin-left: 0;
      }
    }

    // Magic percentages taken from old Bootstrap
    .span1 {
      width: 5.80110497%;
    }

    .span2 {
      width: 14.36464088%;
    }

    .span3 {
      width: 22.9281768%;
    }

    .span4 {
      width: 31.49171271%;
    }

    .span5 {
      width: 40.05524862%;
    }

    .span6 {
      width: 48.61878453%;
    }

    .span7 {
      width: 57.18232044%;
    }

    .span8 {
      width: 65.74585635%;
    }

    .span9 {
      width: 74.30939227%;
    }

    .span10 {
      width: 82.87292818%;
    }

    .span11 {
      width: 91.43646409%;
    }

    .span12 {
      width: 100%;
    }

    // Offsets
    .offset12 {
      margin-left: 105.52486188%;
    }

    .offset12:first-child {
      margin-left: 102.76243094%;
    }

    .offset11 {
      margin-left: 96.96132597%;
    }

    .offset11:first-child {
      margin-left: 94.19889503%;
    }

    .offset10 {
      margin-left: 88.39779006%;
    }

    .offset10:first-child {
      margin-left: 85.63535912%;
    }

    .offset9 {
      margin-left: 79.83425414%;
    }

    .offset9:first-child {
      margin-left: 77.0718232%;
    }

    .offset8 {
      margin-left: 71.27071823%;
    }

    .offset8:first-child {
      margin-left: 68.50828729%;
    }

    .offset7 {
      margin-left: 62.70718232%;
    }

    .offset7:first-child {
      margin-left: 59.94475138%;
    }

    .offset6 {
      margin-left: 54.14364641%;
    }

    .offset6:first-child {
      margin-left: 51.38121547%;
    }

    .offset5 {
      margin-left: 45.5801105%;
    }

    .offset5:first-child {
      margin-left: 42.81767956%;
    }

    .offset4 {
      margin-left: 37.01657459%;
    }

    .offset4:first-child {
      margin-left: 34.25414365%;
    }

    .offset3 {
      margin-left: 28.45303867%;
    }

    .offset3:first-child {
      margin-left: 25.69060773%;
    }

    .offset2 {
      margin-left: 19.88950276%;
    }

    .offset2:first-child {
      margin-left: 17.12707182%;
    }

    .offset1 {
      margin-left: 11.32596685%;
    }

    .offset1:first-child {
      margin-left: 8.56353591%;
    }
  }

  @include media-queries.mq($from: lg) {
    [class*='span'] {
      margin-left: 2.56410256%;

      &:first-child {
        margin-left: 0;
      }
    }

    // Magic percentages taken from old Bootstrap
    .span1 {
      width: 5.98290598%;
    }

    .span2 {
      width: 14.52991453%;
    }

    .span3 {
      width: 23.07692308%;
    }

    .span4 {
      width: 31.62393162%;
    }

    .span5 {
      width: 40.17094017%;
    }

    .span6 {
      width: 48.71794872%;
    }

    .span7 {
      width: 57.26495726%;
    }

    .span8 {
      width: 65.81196581%;
    }

    .span9 {
      width: 74.35897436%;
    }

    .span10 {
      width: 82.90598291%;
    }

    .span11 {
      width: 91.45299145%;
    }

    .span12 {
      width: 100%;
    }

    // Offsets
    .offset12 {
      margin-left: 105.12820513%;
    }

    .offset12:first-child {
      margin-left: 102.56410256%;
    }

    .offset11 {
      margin-left: 96.58119658%;
    }

    .offset11:first-child {
      margin-left: 94.01709402%;
    }

    .offset10 {
      margin-left: 88.03418803%;
    }

    .offset10:first-child {
      margin-left: 85.47008547%;
    }

    .offset9 {
      margin-left: 79.48717949%;
    }

    .offset9:first-child {
      margin-left: 76.92307692%;
    }

    .offset8 {
      margin-left: 70.94017094%;
    }

    .offset8:first-child {
      margin-left: 68.37606838%;
    }

    .offset7 {
      margin-left: 62.39316239%;
    }

    .offset7:first-child {
      margin-left: 59.82905983%;
    }

    .offset6 {
      margin-left: 53.84615385%;
    }

    .offset6:first-child {
      margin-left: 51.28205128%;
    }

    .offset5 {
      margin-left: 45.2991453%;
    }

    .offset5:first-child {
      margin-left: 42.73504274%;
    }

    .offset4 {
      margin-left: 36.75213675%;
    }

    .offset4:first-child {
      margin-left: 34.18803419%;
    }

    .offset3 {
      margin-left: 28.20512821%;
    }

    .offset3:first-child {
      margin-left: 25.64102564%;
    }

    .offset2 {
      margin-left: 19.65811966%;
    }

    .offset2:first-child {
      margin-left: 17.09401709%;
    }

    .offset1 {
      margin-left: 11.11111111%;
    }

    .offset1:first-child {
      margin-left: 8.54700855%;
    }
  }

  @include media-queries.mq($from: sm) {
    // Using flex box for grid rows means we always have equal heights
    // Which is useful for horizontal list of blocks
    @supports (display: flex) {
      align-items: flex-start; // To stop images from stetching, see https://stackoverflow.com/a/44030846/486434
      display: flex; // To force equal heights for grid items
      flex-wrap: wrap; // To force non-span* items to wrap by default

      [class*='span'] {
        align-self: stretch;

        > img {
          align-self: flex-start;
        }
      }
    }
  }
}

@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

/// Navigation header, taken from NICE.Bootstrap
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html
.nav-header {
  @include typography.font-size(1);
  display: block;
  font-weight: bold;
  padding: utils.rem(spacing.$x-small spacing.$small);
}

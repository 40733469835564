@use 'sass:math';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/icons/scss/nice-icons';

// 'Corporate' within a page-header
.navbar-corporate {
  margin: utils.rem(spacing.$medium 0 0);

  // 'Explore' button for toggling the nav on mobile
  .navbar-toggle {
    @include typography.font-size(1);
    display: block;
    margin: 0;
    position: relative;
    width: 100%;
    z-index: 1;

    &:after {
      @include nice-icons.nice-icon(minus);
      display: inline-block;
      line-height: 0;
      margin: utils.rem(0 0 0 spacing.$small);
      vertical-align: middle;
    }

    &.collapsed:after {
      @include nice-icons.nice-icon(plus);
    }
  }

  // List of nav elements
  .nav {
    border-bottom: 1px solid colours.$border;
    float: none;
    height: auto;
    list-style: none;
    margin: 0;
    padding: utils.rem(spacing.$x-small);
    position: relative;

    &.collapse {
      border-bottom: 0;
      height: 0;

      &.in {
        border-bottom: 1px solid colours.$border;
      }
    }

    li {
      &:first-child {
        margin-top: utils.rem(spacing.$medium);
      }

      &:last-child {
        margin-bottom: utils.rem(spacing.$medium);
      }
    }

    a {
      @include typography.font-size(1);
      display: block;
      padding: utils.rem(spacing.$x-small 0);
      position: relative;
      text-decoration: none;

      &:focus {
        z-index: 1;
      }
    }
  }

  @include media-queries.mq($from: xs) {
    .navbar-toggle {
      min-width: 50%;
    }
  }

  @include media-queries.mq($from: sm) {
    .navbar-toggle {
      display: none;
    }

    .nav {
      // Important to override style set on the element via JS
      border-bottom: 1px solid colours.$border !important;
      height: auto !important;

      li {
        float: left;
        width: math.percentage(math.div(1, 3));

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-panel/scss/panel';

// NICE.Bootstrap pager for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html#pagination
.pager {
  margin: utils.rem(spacing.$medium 0 spacing.$large);
  text-align: center;

  // Elements

  ul {
    display: inline-block;
    margin: 0;
  }

  li {
    display: none;

    &.disabled {
      a,
      span {
        background: noc-colours.$bootstrap-pager-disabled-background;
        color: noc-colours.$bootstrap-pager-disabled-text;
        cursor: text;
        pointer-events: none;
      }
    }

    &.active {
      a,
      span {
        background: noc-colours.$bootstrap-pager-active-background;
        color: noc-colours.$bootstrap-pager-active-text;
      }
    }

    &:first-child,
    &:last-child,
    &.active,
    &.next,
    &.previous {
      display: inline;
    }
  }

  a,
  span {
    background: colours.$panel-default-background;
    color: colours.$link;
    display: inline-block;
    margin-left: utils.rem(spacing.$x-small);
    margin-right: utils.rem(spacing.$x-small);
    padding: utils.rem(spacing.$small spacing.$medium);
    position: relative;
    text-decoration: none;
  }

  a {
    &:hover {
      background: noc-colours.$bootstrap-pager-link-hover-background;
    }

    &:active {
      top: utils.rem(spacing.$xx-small);
    }
  }

  // Modifiers

  &.pager-large {
    @include typography.font-size(1);
  }

  &.pager-small {
    @include typography.font-size(-1);
  }

  &.pager-mini {
    @include typography.font-size(-2);
  }

  .pager-centered {
    text-align: center;
  }

  &.pager-right {
    text-align: right;
  }

  @include media-queries.mq($from: sm) {
    li {
      display: inline;
    }
  }
}

@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

/// Glossary - used on glossary page
/// Overrides
/// See https://www.nice.org.uk/glossary for example

.nav-glossary {
  display: flex;
  flex-wrap: wrap;

  > li {
    margin-bottom: 2%;
    margin-right: 2%;
    width: 23%;

    &.active > a {
      background-color: noc-colours.$glossary-link-active-background;
      color: noc-colours.$glossary-link-active-text;
    }

    > a {
      @include typography.font-size(4);
      background-color: noc-colours.$glossary-link-background;
      color: noc-colours.$glossary-link-text;
      display: block;
      font-weight: bold;
      line-height: 1.5em;
      text-align: center;
      text-decoration: none;

      &:hover {
        background-color: noc-colours.$glossary-link-hover-background;
        color: noc-colours.$glossary-link-hover-text;
      }
    }
  }
}

#glossary-list {
  .media-list {
    @include typography.p;
    margin-top: utils.rem(spacing.$large);
  }

  .media-body {
    p {
      margin-bottom: 1rem;
    }
  }
}

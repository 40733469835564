@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';

// Migrated from https://github.com/nice-digital/niceorg/blob/master/src/Orchard.Web/Themes/NICE.Bootstrap/Styles/nice/NICE.comments.less
// See example at https://www.nice.org.uk/news/blog/a-life-changing-operation

.comment-count {
  margin-top: utils.rem(spacing.$large);
}

// List of the comments
.comments {
  margin-bottom: utils.rem(spacing.$large);

  > li {
    margin: utils.rem(0 0 spacing.$medium);

    &.last,
    &:last-child {
      margin: 0;
    }
  }
}

// Individual comment
.comment {
  .comment-author-and-date {
    .who {
      font-weight: bold;
    }
  }

  .text {
    margin: 0;
  }
}

#comment-form-container {
  .comment-text {
    height: utils.rem(spacing.$x-large);
    width: 100%;
  }

  .growbox,
  .comment-text {
    transition: height 0.3s;
  }

  .growbox {
    margin-bottom: utils.rem(spacing.$medium);
    overflow: hidden;

    &.notshown {
      height: 0;
    }
  }
}

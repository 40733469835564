@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

// Social media share links (ie addthis), using a dropdown internally.
// Used at the top of blogs/news.
// E.g. https://www.nice.org.uk/news/blog/having-a-nice-endorsement-ensures-patients-and-commissioners-trust-our-mental-health-services
.share-links {
  .nav-header {
    @include typography.font-size(h5);
    padding: utils.rem(spacing.$small spacing.$medium);
  }

  .navbar-inner {
    background: 0;
  }

  .dropdown-menu {
    margin-top: utils.rem(spacing.$small);
    padding-bottom: utils.rem(spacing.$small);

    &:before {
      border-bottom: utils.rem(spacing.$small) solid colours.$border;
      border-left: utils.rem(spacing.$small) solid transparent;
      border-right: utils.rem(spacing.$small) solid transparent;
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: utils.rem(spacing.$small);
      width: 0;
    }

    &.pull-left:before {
      left: utils.rem(spacing.$small);
    }

    a {
      color: colours.$text;
      margin: 0 !important;
      padding: utils.rem(spacing.$x-small spacing.$medium);
      text-decoration: none;

      &:hover {
        background: colours.$border;
        color: colours.$link-inverse;
      }
    }
  }

  [class^='icon-'] {
    display: inline-block;
    min-width: utils.rem(spacing.$medium);
    text-align: center;
    vertical-align: baseline;
  }
}

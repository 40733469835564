@use 'sass:math';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// Bootstrap's embed responsive component from https://getbootstrap.com/docs/4.0/utilities/embed/.
// Ported from https://github.com/nice-digital/niceorg/blob/master/src/Orchard.Web/Themes/NICE.Bootstrap/Styles/nice/temporary-bootstrap-fixes.less#L552-L588

.embed-responsive {
  display: block;
  height: 0;
  margin-bottom: utils.rem(spacing.$large);
  overflow: hidden;
  padding: 0;
  position: relative;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.embed-responsive-21by9 {
    padding-bottom: math.percentage(math.div(9, 21));
  }

  &.embed-responsive-16by9 {
    padding-bottom: math.percentage(math.div(9, 16));
  }

  &.embed-responsive-4by3 {
    padding-bottom: 75%;
  }

  &.embed-responsive-1by1 {
    padding-bottom: 100%;
  }

  &.wrap {
    float: left;
    margin-right: utils.rem(spacing.$medium);
  }

  // styles copied from bootstrap 3 then put inside a media query.
  @include media-queries.mq($from: sm) {
    &.wrap {
      float: none;
      margin-right: initial;
    }
  }
}

// Embedded infograms share max width with paragraphs
@media print {
  .infogram-embed {
    max-width: 66ch;
  }
}

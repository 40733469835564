@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// Remove the top margin for the first lead paragraph
// on a page so it sits closer to the page header.
.page-content .lead:first-child {
  margin-top: 0;
}

// NICE.Bootstrap abbreviation styles for backwards compatibility,
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#abbreviations
abbr {
  &[title],
  &[data-original-title] {
    cursor: help;
  }

  &.initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
}

// NICE.Bootstrap abbreviation styles for backwards compatibility,
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#addresses
address {
  display: block;
  font-style: normal;
  margin-bottom: utils.rem(spacing.$medium);
}

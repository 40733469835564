@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

// Single blog items listed on news / blog page

.blog-item {
  margin-bottom: utils.rem(spacing.$medium) !important;

  h4 {
    @include typography.font-size(h5);
    margin-top: 0;
  }

  .text-block {
    p:first-child {
      @include typography.font-size(-1);
      color: colours.$text;
      margin: utils.rem(spacing.$small 0 spacing.$x-small 0);
    }

    small {
      @include typography.font-size(-1);
      color: colours.$text;
      display: block;
      margin: utils.rem(spacing.$x-small 0 spacing.$x-small 0);
    }

    .abstract {
      margin: utils.rem(spacing.$small 0 spacing.$x-small 0);
    }
  }
}

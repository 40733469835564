@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

/// Thumbnail component used for displaying news items in a grid
/// e.g. https://www.nice.org.uk/news
/// Adapted from NICE.Bootstrap
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#thumbnails
.thumbnail {
  height: 100%;
  position: relative;

  > img,
  .img-container > img {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  .img-container {
    min-height: 200px;
  }

  .caption {
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include typography.font(h5);
      margin-top: utils.rem(spacing.$medium);
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  a:before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

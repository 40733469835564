@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/glyphs';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/visibility';

// Old NICE.Bootstrap breadcrumbs for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html#breadcrumbs
// Note: these are different markup to the new, Design System breadcrumbs,
// see https://nice-digital.github.io/nice-design-system/components/breadcrumbs/#usage
.breadcrumb {
  @include visibility.hide-print;
  list-style: none;
  margin: utils.rem(spacing.$small 0 spacing.$large);
  max-width: 100%;
  padding: 0;

  li {
    display: inline-block;
    padding-left: 0;
  }

  a {
    color: colours.$text;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  // Divider used to use NICE.Glyphs but we prefer
  // a "greater than" symbol rather than a web font
  .divider {
    color: #004650;
    font-family: typography.get-font-family(sans);
    font-size: 80%;
    margin: utils.rem(0 spacing.$x-small);

    &:before {
      content: glyphs.get-glyph(greater-than);
    }
  }
}

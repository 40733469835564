@use '@nice-digital/icons/scss/nice-icons';

// Old Bootstrap use a mixture of NICE.Glyphs and FontAwesome.
// These have been replaced with, and consolidated into, nice-icons.
// Below we map the FontAwesome icons to the corresponding
// icon in NICE Icons.
// See https://github.com/nice-digital/nice-icons
.fa {
  @include nice-icons.nice-icons-base;

  &.icon-android:before {
    content: nice-icons.nice-icon(android);
  }

  &.icon-apple:before {
    content: nice-icons.nice-icon(apple);
  }

  &.icon-youtube-play:before {
    content: nice-icons.nice-icon(youtube-play);
  }

  &.icon-linkedin-sign:before {
    content: nice-icons.nice-icon(linkedin-sign);
  }
}

// Old icons from NICE-Glyphs are all prefixed with "nice-"
[class*='icon-nice'],
[class*='icon-'] {
  @include nice-icons.nice-icons-base;

  &:before {
    display: block;
  }
}

// Support old icons from NICE-Glyphs with the corresponding icon in nice-icons
@each $icon, $code in nice-icons.$nice-icons {
  .icon-nice-#{ $icon }:before,
  .icon-#{ $icon }:before {
    content: nice-icons.nice-icon($icon);
  }
}

// Custom icons that don't follow the normal conventions and may or may not still be used / needed
.icon-question-sign:before {
  content: nice-icons.nice-icon(question-circle);
}

.icon-volume-up:before {
  content: nice-icons.nice-icon(podcast);
}

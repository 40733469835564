@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// NICE.Bootstrap button groups for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.FormElements.html#buttonGroups
.btn-group {
  display: inline-block;
  font-size: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  > .btn {
    margin: 0;

    + .btn {
      margin-left: 1px;
    }
  }

  &.btn-group-vertical {
    > .btn {
      display: block;
      float: none;
      max-width: 100%;

      + .btn {
        margin-left: 0;
        margin-top: 1px;
      }
    }
  }
}

.btn-toolbar {
  font-size: 0;
  margin: utils.rem(spacing.$medium);

  > .btn-group + .btn-group {
    margin-left: utils.rem(spacing.$small);
  }
}

@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// Support for old NICE.Bootstrap 'well' component, originally from Twitter's bootstrap.
// Essentially a panel, but without the option of a title or footer.
// Used to visuallt group elements in a grey container.
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#wells
.well {
  @include layout.clearfix;
  background: colours.$panel-default-background;
  border: 1px solid colours.$panel-default-border;
  margin: utils.rem(spacing.$medium 0);
  min-height: utils.rem(spacing.$large);
  padding: utils.rem(spacing.$medium);
  page-break-inside: avoid;

  // Support nested wells
  .well,
  .panel & {
    background: colours.$panel-default-background;
  }

  &.well-large {
    padding: utils.rem(spacing.$large);
  }

  &.well-small {
    padding: utils.rem(spacing.$small);
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
  }
}

@use 'sass:color';
@use '../noc-colours';
@use '@nice-digital/nds-button/scss/button';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/typography';

// NICE.Bootstrap buttons for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.FormElements.html#buttons

.btn.active {
  background: noc-colours.$bootstrap-btn-active-background;
  color: noc-colours.$bootstrap-btn-active-text;
}

.btn-primary {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .btn--cta;
}

.btn-info {
  background: colours.$info-background;
  border: 1px solid colours.$info;
  color: colours.$info !important;

  &:hover {
    background: color.adjust(colours.$info-background, $lightness: -5%);
  }

  &:focus {
    background: colours.$info-background;
  }
}

.btn-success {
  background: colours.$success-background;
  border: 1px solid colours.$success;
  color: colours.$success !important;

  &:hover {
    background: color.adjust(colours.$success-background, $lightness: -5%);
  }

  &:focus {
    background: colours.$success-background;
  }
}

.btn-warning {
  background: colours.$caution-background;
  border: 1px solid colours.$caution;
  color: colours.$caution !important;

  &:hover {
    background: color.adjust(colours.$caution-background, $lightness: -5%);
  }

  &:focus {
    background: colours.$caution-background;
  }
}

.btn-danger {
  background: colours.$error-background;
  border: 1px solid colours.$error;
  color: colours.$error !important;

  &:hover {
    background: color.adjust(colours.$error-background, $lightness: -5%);
  }

  &:focus {
    background: colours.$error-background;
  }
}

.btn-inverse {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .btn--inverse;
}

.btn-off {
  opacity: 0.5;
}

.btn-xl {
  @include typography.font-size(4);
}

.btn-large {
  @include typography.font-size(1);
}

.btn-small {
  @include typography.font-size(-1);
}

.btn-mini {
  @include typography.font-size(-2);
}

.btn-link {
  @include typography.link-default;
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: underline !important;

  &:hover {
    background: inherit;
  }

  &:focus {
    background: transparent;
  }

  &:active {
    background: transparent;
    top: 0;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

@charset 'utf-8';

@forward '@nice-digital/design-system/scss/nice-design-system' with (
  $nice-icons-base-path: '../../node_modules/@nice-digital/icons/dist/'
);

@forward 'layout';
@forward 'nice.bootstrap';
@forward 'components';

// Helper to fill height for in page nav JS implementation. Allows position sticky to work.
[data-in-page-nav] {
  height: 100%;
}

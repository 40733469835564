.fade {
  opacity: 0;
  transition: opacity 0.15s linear;

  &.in {
    opacity: 1;
  }
}

.collapse {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;

  &.in {
    height: auto;
  }
}

@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/glyphs';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// NICE.Boostrap overrides for lists
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#lists

// NICE Design System uses list--unstyled rather than just unstyled
ul.unstyled,
ol.unstyled {
  list-style: none;
  margin-left: 0;
}

ul.inline,
ol.inline {
  list-style: none;
  margin-left: 0;

  > li {
    display: inline-block;
    padding-left: utils.rem(spacing.$small);
    padding-right: utils.rem(spacing.$small);
  }
}

ol.alt {
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;

  > li {
    list-style: none;
    margin-bottom: utils.rem(spacing.$small);
    margin-left: utils.rem(spacing.$large + spacing.$small);
    min-height: utils.rem(spacing.$large);
    position: relative;

    &:before {
      background: colours.$icons;
      border-radius: 50%;
      color: colours.$text-inverse;
      content: counter(li);
      counter-increment: li;
      font-weight: bold;
      height: utils.rem(spacing.$large);
      left: -(utils.rem(spacing.$large + spacing.$small));
      line-height: utils.rem(spacing.$large);
      margin-top: -(utils.rem(spacing.$x-small));
      position: absolute;
      text-align: center;
      text-indent: 0;
      width: utils.rem(spacing.$large);
    }
  }
}

ul.alt {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  > li {
    list-style: none;
    margin-left: utils.rem(spacing.$medium);
    min-height: utils.rem(spacing.$small);
    position: relative;

    &:before {
      color: colours.$icons;
      content: glyphs.get-glyph(bullet);
      font-size: 125%;
      left: -(utils.rem(spacing.$medium));
      position: absolute;
    }
  }
}

@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/typography';

// Old NICE.Bootstrap alert component for backwards compatibility
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#alerts
.alert {
  background: colours.$caution-background;
  border: 1px solid colours.$caution;
  color: colours.$caution;
  margin: utils.rem(spacing.$medium 0);
  padding: utils.rem(
    spacing.$small spacing.$large spacing.$small spacing.$small
  );

  // Elements

  .close {
    position: relative;
    right: -(utils.rem(spacing.$medium));
  }

  h4 {
    clear: none;
    margin: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }

  // Modifiers

  &.alert-block {
    padding: utils.rem(
      spacing.$medium spacing.$large spacing.$medium spacing.$medium
    );
  }

  &.alert-danger,
  &.alert-error {
    background-color: colours.$error-background;
    border-color: colours.$error;
    color: colours.$error;
  }

  &.alert-success {
    background-color: colours.$success-background;
    border-color: colours.$success;
    color: colours.$success;
  }

  &.alert-info {
    background-color: colours.$info-background;
    border-color: colours.$info;
    color: colours.$info;
  }
}

@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

.news-content {
  .blog-author {
    @include typography.font-size(h5);
    color: colours.$text;
    display: block;
    font-weight: normal;
    margin-top: utils.rem(spacing.$small);
  }

  .date {
    color: colours.$text;
    margin-bottom: utils.rem(spacing.$small);
    margin-top: 0;
  }

  .news-header {
    h1 {
      clear: none;
    }
  }

  img {
    height: auto; // Some CMS images have inline height/width attributes
    padding-right: utils.rem(spacing.$medium);
  }

  p:first-child {
    margin-top: 0;
  }
}

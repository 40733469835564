@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/icons/scss/nice-icons';

// Key links used to use NICE.Glyphs. Now use nice-icons instead
.key-link:before {
  @include nice-icons.nice-icon(chevron-right);
  @include typography.font-size(-2);
  color: inherit;
  font-weight: normal;
  margin: utils.rem(0 spacing.$small 0 0);
  text-decoration: none;
}

// Temporary fix for duplicate close icons in guidance page filter summary
/* stylelint-disable-next-line selector-class-pattern */
a.tag__remove span.icon--remove {
  display: none;
}

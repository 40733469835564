@use '../../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

/// Navigation bar, taken from NICE.Bootstrap
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html#navbar
.navbar {
  list-style: none;
  margin-left: 0;
  overflow: visible;

  .container {
    width: auto;
  }

  .brand {
    @include typography.font-size(4);
    color: colours.$text;
    display: block;
    float: left;
    font-weight: bolder;
    padding: utils.rem(spacing.$small spacing.$medium);
    text-decoration: none;
  }

  .nav {
    display: block;
    float: left;
    margin: 0;

    &.pull-right {
      float: right;
    }

    > li {
      width: 100%;

      > a {
        color: colours.$text;
        display: block;
        padding: utils.rem(spacing.$small spacing.$medium);
        text-decoration: none;

        &:hover {
          background: noc-colours.$bootstrap-navbar-link-hover-background;
        }
      }

      &.active {
        > a {
          background: noc-colours.$bootstrap-navbar-active-background;
          color: colours.$text-inverse;
        }
      }
    }
  }

  .navbar-inner {
    @include layout.clearfix;
    background: noc-colours.$bootstrap-navbar-inner-background;
  }

  &.navbar-inverse {
    // stylelint-disable max-nesting-depth
    .nav {
      > li {
        > a {
          color: colours.$text-inverse;

          &:hover {
            background: noc-colours.$bootstrap-navbar-inverse-link-hover-background;
          }
        }

        &.active {
          > a {
            background: noc-colours.$bootstrap-navbar-inverse-active-background;
            color: colours.$text;
          }
        }
      }
    }

    .navbar-inner {
      background: noc-colours.$bootstrap-navbar-inverse-inner-background;
    }
  }

  @include media-queries.mq($from: md) {
    .nav {
      > li {
        float: left;
        width: auto;
      }
    }
  }
}

@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

/// Media object - used for displaying news etc.
/// Taken from NICE.Bootstrap
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#media
.media {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: utils.rem(spacing.$small 0);
  position: relative;

  // Use font-size 0 to get rid of &nbsp; characters
  .media-heading {
    font-size: 0;
    margin: 0;
    max-width: 100%;
    padding-top: 3px; // Make room for focus ring

    a {
      @include typography.h5;
      display: inline-block;
      margin-top: 0;
      max-width: 66ch;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
      }
    }
  }

  h4.media-heading {
    @include typography.font-size(h4);
  }

  h5.media-heading {
    @include typography.font-size(h5);
  }

  .media-body {
    overflow: hidden;
    padding: 0 3px; // Make room for the focus ring

    p {
      margin: utils.rem(spacing.$x-small 0 0);
    }
  }

  .media-date {
    @include typography.font-size(-1);
    color: noc-colours.$bootstrap-media-date-text;
    position: relative;
    z-index: 1;
  }

  > .pull-left,
  > .pull-right {
    display: block;
    float: none;
    margin-bottom: utils.rem(spacing.$medium);
    width: 150px;
  }

  // Modifiers

  &.media-resource {
    .panel-resources & {
      margin-bottom: 0;
      padding: utils.rem(spacing.$small spacing.$medium);

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &.noborder,
  &.no-border {
    border: 0 !important;
  }

  .media-list & {
    border-bottom: 1px solid colours.$border;
    padding-bottom: utils.rem(spacing.$medium);
    padding-top: utils.rem(spacing.$medium);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  // Some media items are in a plain list so break out of the default margin
  ul:not(.media-list) & {
    margin-left: -(utils.rem(spacing.$medium));
  }

  @include media-queries.mq($from: sm) {
    @include layout.clearfix;

    > .pull-left {
      float: left;
      margin-bottom: 0;
      margin-right: utils.rem(spacing.$medium);
    }

    > .pull-right {
      float: right;
      margin-bottom: 0;
      margin-left: utils.rem(spacing.$medium);
    }
  }
}

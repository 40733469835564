@use '../noc-colours';
@use '@nice-digital/nds-tag/scss/tag';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// NICE.Bootstrap pager for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#labels-badges
.label,
.badge {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .tag;

  &.label-success,
  &.badge-success {
    background: colours.$success-background;
    border: 1px solid colours.$success;
    color: colours.$success;
  }

  &.label-warning,
  &.badge-warning {
    background: colours.$caution-background;
    border: 1px solid colours.$caution;
    color: colours.$caution;
  }

  &.label-important,
  &.badge-important {
    background: colours.$error-background;
    border: 1px solid colours.$error;
    color: colours.$error;
  }

  &.label-info,
  &.badge-info {
    background: colours.$info-background;
    border: 1px solid colours.$info;
    color: colours.$info;
  }

  &.label-inverse,
  &.badge-inverse {
    background: noc-colours.$bootstrap-badge-inverse-background;
    color: noc-colours.$bootstrap-badge-inverse-text;
  }
}

.badge {
  padding-left: utils.rem(spacing.$small);
  padding-right: utils.rem(spacing.$small);
}

@use '../../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

// Old NICE.Bootstrap nav list for backwards compatability
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Navigation.html#navs
.nav-list {
  background: noc-colours.$bootstrap-navlist-background;

  .divider {
    background-color: colours.$border;
    height: 1px;
    margin: utils.rem(spacing.$small 0);
    overflow: hidden;
  }

  a {
    color: colours.$text;
    text-decoration: none;

    &:hover {
      background: noc-colours.$bootstrap-navlist-link-hover-background;
    }
  }

  > li > a {
    background: noc-colours.$bootstrap-navlist-link-background;
    display: block;
    padding: utils.rem(spacing.$x-small spacing.$small);
    position: relative;
  }

  .active > a {
    background: noc-colours.$bootstrap-navlist-link-active-background;
    color: colours.$text-inverse;

    &:after {
      border: utils.rem(spacing.$small) solid transparent;
      border-left-color: noc-colours.$bootstrap-navlist-link-active-background;
      content: '';
      height: 0;
      left: 100%;
      margin-top: -(utils.rem(spacing.$small));
      position: absolute;
      top: 50%;
      width: 0;
    }
  }
}

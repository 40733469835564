@use '@nice-digital/nds-core/scss/media-queries';

// stylelint-disable number-max-precision

// Custom grid integration from NICE.bootstrap
%grid-base-styling {
  list-style: none;
  margin: 0;
  max-width: 100%; // To override the 66ch max-width on lists
  padding: 0;
  width: auto;

  &:before,
  &:after {
    content: '';
    display: table;
    line-height: 0;
  }

  &:after {
    clear: both;
  }

  > * {
    box-sizing: border-box;
    float: left;
    list-style: none;
    margin: 0 1.9% 0 0;
    min-height: 1px;
    padding: 0;
  }
}

.grid2 {
  @extend %grid-base-styling;
  @include media-queries.mq($from: xs) {
    > * {
      width: 48%;

      &:nth-child(2n + 3) {
        clear: left;
      }
    }
  }
  @include media-queries.mq($until: xs) {
    > * {
      float: none;
      margin-right: 0;
      width: 100%;
    }

    > *:nth-child(2n + 3) {
      clear: none;
    }
  }
}

.grid3 {
  @extend %grid-base-styling;
  @include media-queries.mq($from: sm) {
    > * {
      width: 31.333333%;

      &:nth-child(3n + 4) {
        clear: left;
      }
    }

    > .over2 {
      width: 64.666666%;
    }
  }
  @include media-queries.mq($until: sm) {
    text-align: center;

    > * {
      display: inline-block;
      float: none;
      text-align: left;
      width: 47.6%;

      &:nth-child(3n + 4) {
        clear: none;
      }

      &:nth-child(2n + 3) {
        clear: left;
      }
    }

    > .over2,
    > .over-fill {
      display: block;
      margin-bottom: 3px;
      width: 97.1%;
    }
  }
  @include media-queries.mq($until: xs) {
    > *,
    > .over2,
    > .over-fill {
      display: block;
      margin-right: 0;
      width: 100%;
    }

    > *:nth-child(2n + 3) {
      clear: none;
    }
  }
}

.grid4 {
  @extend %grid-base-styling;
  @include media-queries.mq($from: sm) {
    > * {
      width: 23%;
    }

    > *:nth-child(4n + 5) {
      clear: left;
    }

    > .over2 {
      width: 48%;
    }

    > .over3 {
      width: 73%;
    }
  }

  @include media-queries.mq($until: sm) {
    text-align: center;

    > * {
      display: inline-block;
      float: none;
      margin: -2px -1px;
      text-align: left;
      width: 47.6%;
    }

    > *:nth-child(4n + 5) {
      clear: none;
    }

    > *:nth-child(3n + 4) {
      clear: left;
    }

    > .over2,
    > .over3,
    > .over-fill {
      display: block;
      margin-bottom: 3px;
      width: 97.1%;
    }
  }
  @include media-queries.mq($until: xs) {
    > *:nth-child(3n + 4) {
      clear: none;
    }

    > *:nth-child(2n + 3) {
      clear: left;
    }

    > *,
    > .over2,
    > .over3,
    > .over-fill {
      display: block;
      margin-right: 0;
      width: 100%;
    }
  }
}

.grid5 {
  @extend %grid-base-styling;
  @include media-queries.mq($from: sm) {
    > * {
      width: 18%;
    }

    > .over2 {
      width: 38%;
    }

    > .over3 {
      width: 58%;
    }

    > .over4 {
      width: 78%;
    }
  }
  @include media-queries.mq($until: sm) {
    > * {
      display: inline-block;
      float: none;
      margin: -2px -1px;
      text-align: left;
      width: 31.11158%;
    }

    > .over2 {
      width: 64.666666%;
    }

    > .over3,
    > .over4,
    > .over-fill {
      display: block;
      margin-bottom: 3px;
      width: auto;
    }
  }
  @include media-queries.mq($until: xs) {
    > *,
    > .over2,
    > .over3,
    > .over4,
    > .over-fill {
      display: block;
      margin: 0;
      width: auto;
    }
  }
}

.grid6 {
  @extend %grid-base-styling;
  @include media-queries.mq($from: sm) {
    > * {
      width: 14.666666%;
    }

    > .over2 {
      width: 31.11158%;
    }

    > .over3 {
      width: 46.7%;
    }

    > .over4 {
      width: 64.666666%;
    }

    > .over5 {
      width: 81.333333%;
    }
  }
  @include media-queries.mq($until: sm) {
    text-align: center;

    > * {
      display: inline-block;
      float: none;
      margin: -2px -1px;
      text-align: left;
      width: 31.11158%;
    }

    > .over2 {
      width: 64.666666%;
    }

    > .over3,
    > .over4,
    > .over5,
    > .over-fill {
      display: block;
      margin-bottom: 3px;
      width: auto;
    }
  }
  @include media-queries.mq($until: xs) {
    > *,
    > .over2,
    > .over3,
    > .over4,
    > .over5 {
      display: block;
      margin: 0;
      width: auto;
    }
  }
}

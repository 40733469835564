@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/focus';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

// Fieldsets/legends

fieldset {
  border: 0;
  margin: 0;
  padding: 0;

  &.well {
    padding-top: utils.rem(spacing.$medium) !important;
  }
}

legend {
  @include typography.font(h4);
  border: 0;
  display: block;
  font-weight: bold;
  padding: 0;
  width: 100%;
}

// Control groups

.control-group {
  margin-bottom: utils.rem(spacing.$medium);

  .controls {
    br {
      content: '';
      display: block;
      margin-bottom: utils.rem(spacing.$medium);
    }
  }
}

.control-label {
  display: block;
  margin: utils.rem(0 0 spacing.$x-small);
}

// Inputs

.input-mini {
  width: 60px;
}

.input-small {
  width: 90px;
}

.input-medium {
  width: 150px;
}

.input-large {
  width: 210px;
}

.input-xlarge {
  width: 270px;
}

.input-xxlarge {
  width: 530px;
}

select,
textarea,
input:not([type='reset']):not([type='submit']):not([type='file']):not([type='checkbox']):not([type='radio']):not(#global-nav-header > *), // 'Text' inputs
.uneditable-input {
  background-color: noc-colours.$bootstrap-input-background;
  border: utils.rem(spacing.$xx-small) solid colours.$border;
  color: colours.$text;
  display: inline-block;
  font-size: inherit;
  margin: 0 0 1rem;
  max-width: 66ch;
  padding: utils.rem(spacing.$small);
  transition: border linear 0.2s;
  vertical-align: middle;
  width: 100%;

  &::placeholder {
    color: noc-colours.$bootstrap-input-placeholder;
  }
}

input[type='checkbox'],
input[type='radio'] {
  margin-right: utils.rem(spacing.$small);
}

input,
textarea,
select {
  &:focus {
    @include focus.default-focus-style;

    &:invalid {
      border-color: colours.$error;
      outline-color: colours.$error;
    }
  }

  &::placeholder {
    color: noc-colours.$bootstrap-input-placeholder;
  }

  .warning & {
    border-color: colours.$caution !important;
    color: colours.$caution;
  }

  .error & {
    border-color: colours.$error !important;
    color: colours.$error;
  }

  .info & {
    border-color: colours.$info !important;
    color: colours.$info;
  }

  .success & {
    border-color: colours.$success !important;
    color: colours.$success;
  }
}

// Validation

.field-validation-error,
.alert-required {
  color: colours.$error;
}

// Help/hints

.help-block,
.help-inline,
label {
  color: noc-colours.$bootstrap-input-help;
  display: block;

  .warning & {
    color: colours.$caution;
  }

  .error & {
    color: colours.$error;
  }

  .info & {
    color: colours.$info;
  }

  .success & {
    color: colours.$success;
  }
}

label {
  display: block;
  font-weight: bold;

  &.checkbox {
    padding-left: 0;
  }
}

.checkbox {
  &.inline {
    display: inline-block;
    margin-right: utils.rem(spacing.$large);
  }
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  background-color: noc-colours.$bootstrap-input-disabled-background;
  cursor: not-allowed;
}

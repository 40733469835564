@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/typography';

.stats {
  @include typography.font-size(-1);
  color: colours.$text;
  display: block;
  font-weight: normal;
  margin: 0;

  .data-size {
    @include typography.font-size(-1);
    color: colours.$text;
  }

  .media & {
    position: relative;
    z-index: 1;
  }
}

@use 'sass:math';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/media-queries';

/// 3 column navigation on desktop
/// 2 columns on tablet
/// 1 column on mobile
/// See https://github.com/nice-digital/niceorg/blob/master/src/Orchard.Web/Themes/NICE.Bootstrap/Styles/nice/NICE.tabbed.news.menu.less#L4-L19
/// Note: this is used at the top of find guidance page or the guidance 'pills'.
/// See components/_guidance-pills.scss
.nav-grid3 {
  @include layout.clearfix;
  list-style: none;
  margin: 0;
  max-width: 100%; // To override the 66ch max-width on lists

  > li {
    float: left;
    margin-bottom: 2%;
    text-align: left;
    width: 100%;
  }

  @include media-queries.mq($from: sm) {
    margin-right: -2%;

    > li {
      margin-right: 2%;
      width: 48%;

      &:nth-child(2n + 1) {
        clear: both;
      }
    }
  }

  @include media-queries.mq($from: md) {
    margin-right: -2%;

    > li {
      margin-right: 2%;
      width: math.percentage(math.div(1, 3)) - 2%;

      &:nth-child(2n + 1) {
        clear: none;
      }

      &:nth-child(3n + 4) {
        clear: both;
      }
    }
  }
}

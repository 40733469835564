@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';

@use '@nice-digital/icons/scss/nice-icons';

/// Accordion- used on NICE field team page
/// Overrides
/// See https://www.nice.org.uk/about/what-we-do/into-practice/nice-field-team for example

.accordion {
  .accordion-group {
    background: colours.$panel-default-background;
    margin-bottom: utils.rem(spacing.$small);
  }

  .accordion-heading {
    margin: 0;

    a {
      @include typography.font-size(h5);
      display: block;
      padding: utils.rem(spacing.$small);
      text-decoration: none;
    }
  }

  .accordion-inner {
    padding: utils.rem(spacing.$medium) utils.rem(spacing.$large)
      utils.rem(spacing.$medium) utils.rem(spacing.$medium);

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.accordion-toggle {
  @include typography.font-size(h5);
  display: block;
  padding: utils.rem(spacing.$small);
  text-decoration: none;

  &:before {
    // Sometimes accordion toggles can be key-links so avoid the extra chevron
    display: none !important;
  }

  &:after {
    @include nice-icons.nice-icon(chevron-down);
    float: right;
  }
}

.accordion-body {
  &.collapse {
    @media (prefers-reduced-motion: reduce) {
      transition-timing-function: step-start; // BS seems to rely on a transition to happen to trigger the close state so transition: none; won't cut it
    }

    &:not(.in) > * {
      display: none;
    }
  }
}

@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/focus';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

/// Utility classes, adapted from NICE.Bootstrap

/// Float an element left
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#helper-classes
.pull-left {
  float: left;
}

/// Float an element right
/// See http://nice-digital.github.io/NICE.Bootstrap/Guide.Scaffolding.html#helper-classes
.pull-right {
  float: right;
}

// CSS triangle, often used within dropdowns
.caret {
  border-left: utils.rem(spacing.$small) solid transparent;
  border-right: utils.rem(spacing.$small) solid transparent;
  border-top: utils.rem(spacing.$small) solid noc-colours.$caret-fill;
  content: '';
  display: inline-block;
  height: 0;
  vertical-align: top;
  width: 0;
}

// Generic close button, for alerts etc, see http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#alerts
a.close,
button.close,
input.close {
  @include typography.font-size(3);
  appearance: none;
  background: 0;
  border: 0;
  color: noc-colours.$bootstrap-close-text;
  cursor: pointer;
  float: right;
  line-height: 1;
  padding: 0;
  text-decoration: none;

  &:focus {
    @include focus.default-focus-style;
  }

  &:hover {
    color: noc-colours.$bootstrap-close-hover-text;
  }
}

.muted {
  color: noc-colours.$text-subtle;
}

.text-warning {
  color: colours.$caution;
}

.text-error {
  color: colours.$error;
}

.text-info {
  color: colours.$info;
}

.text-success {
  color: colours.$success;
}

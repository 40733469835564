@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

.container {
  @include layout.clearfix;
}

// Various containers wrapping content from old Bootstrap.
// The specific selectors are needed to override existing styles
body > .layout,
.restrict-width,
body .container {
  @include layout.container;
}

// Some pages (e.g. http://localhost:8087/about/what-we-do/into-practice/audit-and-service-improvement/assessment-tools/)
// weirdly have nested container so we reset the inner container
body > .layout .layout.container,
.container .container {
  max-width: 100%;
  width: auto;
}

header.container {
  margin: 0 auto utils.rem(spacing.$large);

  .author {
    clear: both;
  }
}

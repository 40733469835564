@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

// Page header component overrides from the Design System.
// Groups breadcrumbs and main page heading in niceorg.
.page-header {
  h1 {
    margin: utils.rem(0 0 spacing.$small 0);
  }

  h2 {
    margin: utils.rem(0 0 spacing.$small 0);
  }

  .lead {
    margin: utils.rem(0 0 spacing.$medium 0);
  }
}

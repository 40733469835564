@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

.media-stack {
  height: utils.rem(120px);
  position: relative;

  .media-clip {
    display: block;
    height: utils.rem(120px);
  }

  .media-object {
    height: auto;
    margin: 0 auto;
    width: 100%;
  }

  .media-icon {
    @include typography.font-size(4);
    background: noc-colours.$bootstrap-media-stack-icon-background;
    color: noc-colours.$bootstrap-media-stack-icon-text;
    left: 50%;
    opacity: 0.7;
    padding: utils.rem(spacing.$small spacing.$medium);
    position: absolute;
    text-decoration: none;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .media-icon {
      background: noc-colours.$bootstrap-media-stack-hover-icon-background;
    }
  }

  // Modifiers

  &.media-stack-2x {
    height: utils.rem(140px);

    .media-clip {
      height: utils.rem(140px);
    }

    .media-icon {
      @include typography.font-size(4);
    }
  }

  &.media-stack-3x {
    height: utils.rem(150px);

    .media-clip {
      height: utils.rem(150px);
    }

    .media-icon {
      @include typography.font-size(5);
    }
  }

  &.media-stack-4x,
  &.media-stack-5x {
    height: utils.rem(160px);

    .media-clip {
      height: utils.rem(160px);
    }

    .media-icon {
      @include typography.font-size(5);
    }
  }
}

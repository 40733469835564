@use 'sass:color';
@use '../noc-colours';
@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/media-queries';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/visibility';

// Back to top link, only visible on mobile
#toTop {
  @include visibility.hide-print;
  @include typography.font-size(4);
  background-color: noc-colours.$back-to-top-background;
  border: 1px solid noc-colours.$back-to-top-border;
  border-radius: 0 0 999px 999px;
  border-width: 0 1px 1px;
  color: noc-colours.$back-to-top-text;
  cursor: pointer;
  display: none;
  height: auto;
  left: 50%;
  line-height: 0;
  margin: 0;
  padding: utils.rem(0 spacing.$small spacing.$x-small spacing.$small);
  position: fixed;
  text-align: center;
  top: 0;
  transform: translate(-50%, 0);
  vertical-align: middle;
  width: auto;
  z-index: 3;

  &.show {
    display: block;
  }

  @include media-queries.mq($from: md) {
    display: none !important;
  }
}

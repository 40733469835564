@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/utils';

@keyframes progress-bar-stripes {
  from {
    background-position: utils.rem(spacing.$medium) 0;
  }

  to {
    background-position: 0 0;
  }
}

// NICE.Bootstrap progress for backwards compatability
// See http://nice-digital.github.io/NICE.Bootstrap/Guide.BasicElements.html#progress
.progress {
  height: utils.rem(spacing.$medium);
  margin: utils.rem(0 0 spacing.$medium);
  overflow: hidden;

  // Elements

  .bar {
    background: colours.$icons;
    float: left;
    height: 100%;
    transition: width 0.6s ease;
  }

  &.progress-info .bar,
  .bar-info {
    background: colours.$info;
  }

  &.progress-success .bar,
  .bar-success {
    background: colours.$success;
  }

  &.progress-warning .bar,
  .bar-warning {
    background: colours.$caution-background;
  }

  &.progress-danger .bar,
  .bar-danger {
    background: colours.$error-background;
  }

  // Modifiers

  &.progress-striped {
    .bar {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: utils.rem(spacing.$medium spacing.$medium);
    }
  }

  &.active {
    .bar {
      animation: progress-bar-stripes 1s linear infinite;
    }
  }
}

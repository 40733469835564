@use '@nice-digital/nds-core/scss/colours';
@use '@nice-digital/nds-core/scss/layout';
@use '@nice-digital/nds-core/scss/spacing';
@use '@nice-digital/nds-core/scss/typography';
@use '@nice-digital/nds-core/scss/utils';

// Tabs navigation.
// E.g. https://www.nice.org.uk/about/what-we-do/into-practice/audit-and-service-improvement/assessment-tools
// E.g. https://www.nice.org.uk/standards-and-indicators
.nav-tabs {
  @include layout.clearfix;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: 1px;

  li {
    float: left;

    &.pull-right {
      float: right;
    }

    &.active {
      a {
        background: colours.$panel-default-background;
        border-left: 1px solid colours.$border;
        border-right: 1px solid colours.$border;
        color: colours.$text;
        text-decoration: none;

        &:before {
          // The -1px is to over the grey border round the tab
          background: colours.$icons;
          content: '';
          height: utils.rem(spacing.$x-small);
          left: -1px;
          position: absolute;
          right: -1px;
          top: 0;
        }
      }
    }
  }

  a {
    @include typography.link-default;
    background: 0;
    border: 0;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    cursor: pointer;
    display: block;
    margin: 0;
    padding: utils.rem(spacing.$medium);
    position: relative;

    &:focus {
      // Stop focus ring clipping
      z-index: 1;
    }

    // Some sets of tabs have a 'help' .btn - but we don't want this styled as a button
    &.btn {
      text-decoration: underline !important;

      &:hover,
      &:active,
      &:focus {
        background: none;
        top: 0;
      }
    }

    &.dropdown-toggle {
      background: colours.$panel-default-background;
      border: 1px solid colours.$border;
      padding: utils.rem(spacing.$small spacing.$medium);

      .caret {
        border-top-color: colours.$link;
        margin-top: utils.rem(-(spacing.$x-small));
        vertical-align: middle;
      }
    }
  }

  .dropdown-menu {
    li {
      float: none;
    }

    a {
      clear: both;
      display: block;
      padding: utils.rem(spacing.$small);
    }
  }
}

.tab-content {
  background: colours.$panel-default-background;
  border: 1px solid colours.$border;
  clear: both;
  margin-bottom: utils.rem(spacing.$large);
  padding: utils.rem(spacing.$medium);

  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}
